{
  "white": "#fff",
  "black": "#000000",
  "blue.1": "#e5f3ff",
  "blue.2": "#98c6f2",
  "blue.3": "#72b0ea",
  "blue.4": "#4c9ae2",
  "blue.5": "#2684da",
  "blue.6": "#006ed2",
  "blue.7": "#005db5",
  "blue.8": "#004d96",
  "blue.9": "#003d77",
  "blue.10": "#001d39",
  "purple.1": "#efedf7",
  "purple.2": "#cfcbe6",
  "purple.3": "#b0aad4",
  "purple.4": "#9189c2",
  "purple.5": "#7268b0",
  "purple.6": "#6454bf",
  "purple.7": "#5448a4",
  "purple.8": "#453b87",
  "purple.9": "#362e6a",
  "purple.10": "#27214d",
  "cyan.1": "#f5fefb",
  "cyan.2": "#e2fdf4",
  "cyan.3": "#c4fbe9",
  "cyan.4": "#9df8db",
  "cyan.5": "#8ddfc5",
  "cyan.6": "#7ec6af",
  "cyan.7": "#6eae99",
  "cyan.8": "#5e9583",
  "cyan.9": "#4f7c6e",
  "cyan.10": "#101916",
  "green.1": "#f5f7ed",
  "green.2": "#e2e8cf",
  "green.3": "#d1dbb1",
  "green.4": "#c0ce93",
  "green.5": "#afc175",
  "green.6": "#9eb457",
  "green.7": "#839248",
  "green.8": "#667238",
  "green.9": "#495228",
  "green.10": "#2c3218",
  "magenta.1": "#f9ecf6",
  "magenta.2": "#efd3eb",
  "magenta.3": "#e7b9df",
  "magenta.4": "#df9fd3",
  "magenta.5": "#cf6bbb",
  "magenta.6": "#c751af",
  "magenta.7": "#a94793",
  "magenta.8": "#6d2d5f",
  "magenta.9": "#4f2045",
  "magenta.10": "#31132b",
  "red.1": "#faebeb",
  "red.2": "#f1cbca",
  "red.3": "#e6acab",
  "red.4": "#db8d8c",
  "red.5": "#c54f4e",
  "red.6": "#ba302f",
  "red.7": "#982925",
  "red.8": "#77201d",
  "red.9": "#561715",
  "red.10": "#350e0d",
  "orange.1": "#fff7e6",
  "orange.2": "#ffe7ba",
  "orange.3": "#ffd591",
  "orange.4": "#ffc069",
  "orange.5": "#ffa940",
  "orange.6": "#fa8c16",
  "orange.7": "#d46b08",
  "orange.8": "#ad4e00",
  "orange.9": "#873800",
  "orange.10": "#612500",
  "yellow.1": "#fffdec",
  "yellow.2": "#fff7b3",
  "yellow.3": "#fff48c",
  "yellow.4": "#ffec40",
  "yellow.5": "#e6d43a",
  "yellow.6": "#b3a52d",
  "yellow.7": "#998e26",
  "yellow.8": "#807620",
  "yellow.9": "#665e1a",
  "yellow.10": "#4c4713",
  "volcano.1": "#fff2e8",
  "volcano.2": "#ffd8bf",
  "volcano.3": "#ffbb96",
  "volcano.4": "#ff9c6e",
  "volcano.5": "#ff7a45",
  "volcano.6": "#fa541c",
  "volcano.7": "#d4380d",
  "volcano.8": "#ad2102",
  "volcano.9": "#871400",
  "volcano.10": "#610b00",
  "geekblue.1": "#e5f9ff",
  "geekblue.2": "#bee8f5",
  "geekblue.3": "#98d6ea",
  "geekblue.4": "#4cb2d4",
  "geekblue.5": "#26a0c9",
  "geekblue.6": "#008ebe",
  "geekblue.7": "#007ca1",
  "geekblue.8": "#006786",
  "geekblue.9": "#00526b",
  "geekblue.10": "#003d50",
  "lime.1": "#f8fbe9",
  "lime.2": "#f0f8d6",
  "lime.3": "#eaf5c3",
  "lime.4": "#e4f2b0",
  "lime.5": "#deef9d",
  "lime.6": "#b2c270",
  "lime.7": "#8a9755",
  "lime.8": "#626c3a",
  "lime.9": "#3a411f",
  "lime.10": "#121604",
  "gold.1": "#fdf8ee",
  "gold.2": "#fae9cd",
  "gold.3": "#f6deb0",
  "gold.4": "#f2d393",
  "gold.5": "#eabd59",
  "gold.6": "#bb9747",
  "gold.7": "#8c7135",
  "gold.8": "#755f2d",
  "gold.9": "#5e4c24",
  "gold.10": "#413314",
  "colorTextBase": "#000000",
  "colorTextLightSolid": "#ffffff",
  "colorBgBase": "#ffffff",
  "fontFamily": "Inter",
  "fontSize": 14,
  "lineWidth": 1,
  "borderRadius": 6,
  "sizeUnit": 4,
  "sizePopupArrow": 16,
  "controlHeight": 32,
  "opacityImage": 1,
  "sizeStep": 4,
  "sizeXXL": 48,
  "sizeXL": 32,
  "sizeLG": 24,
  "sizeMD": 20,
  "sizeMS": 16,
  "size": 16,
  "sizeSM": 12,
  "sizeXS": 8,
  "sizeXXS": 4,
  "controlHeightSM": 24,
  "controlHeightXS": 16,
  "controlHeightLG": 40,
  "borderRadiusXS": 2,
  "borderRadiusSM": 4,
  "borderRadiusLG": 8,
  "borderRadiusOuter": 4,
  "colorText": "rgba(0, 0, 0, 0.88)",
  "colorTextSecondary": "rgba(0, 0, 0, 0.65)",
  "colorTextTertiary": "rgba(0, 0, 0, 0.45)",
  "colorTextQuaternary": "rgba(0, 0, 0, 0.25)",
  "colorFill": "rgba(0, 0, 0, 0.15)",
  "colorFillSecondary": "rgba(0, 0, 0, 0.06)",
  "colorFillTertiary": "rgba(0, 0, 0, 0.04)",
  "colorBgContainer": "#ffffff",
  "colorFillQuaternary": "rgba(0, 0, 0, 0.02)",
  "colorBgLayout": "#f5f5f5",
  "colorBgElevated": "#ffffff",
  "colorBgSpotlight": "rgba(0, 0, 0, 0.85)",
  "colorBorder": "rgba(0, 0, 0, 0.15)",
  "colorBorderSecondary": "rgba(0, 0, 0, 0.06)",
  "colorSplit": "rgba(0, 0, 0, 0.06)",
  "colorPrimaryBg": "#efedf7",
  "colorPrimaryBgHover": "#cfcbe6",
  "colorPrimaryBorder": "#b0aad4",
  "colorPrimaryBorderHover": "#9189c2",
  "colorPrimaryHover": "#7268b0",
  "colorPrimary": "#6454bf",
  "colorPrimaryActive": "#5448a4",
  "colorPrimaryTextHover": "#453b87",
  "colorPrimaryText": "#362e6a",
  "colorPrimaryTextActive": "#27214d",
  "colorSuccessBg": "#f5fefb",
  "colorSuccessBgHover": "#e2fdf4",
  "colorSuccessBorder": "#c4fbe9",
  "colorSuccessBorderHover": "#9df8db",
  "colorSuccessHover": "#8ddfc5",
  "colorSuccessBase": "#7ec6af",
  "colorSuccessActive": "#6eae99",
  "colorSuccessTextHover": "#5e9583",
  "colorSuccessText": "#4f7c6e",
  "colorSuccessTextActive": "#101916",
  "colorWarningBg": "#fdf8ee",
  "colorWarningBgHover": "#fae9cd",
  "colorWarningBorder": "#f6deb0",
  "colorWarningBorderHover": "#eabd59",
  "colorWarningHover": "#f2d393",
  "colorWarningBase": "#bb9747",
  "colorWarningActive": "#8c7135",
  "colorWarningTextHover": "#755f2d",
  "colorWarningText": "#5e4c24",
  "colorWarningTextActive": "#413314",
  "colorErrorBg": "#faebeb",
  "colorErrorBgHover": "#f1cbca",
  "colorErrorBorder": "#e6acab",
  "colorErrorBorderHover": "#db8d8c",
  "colorErrorHover": "#db8d8c",
  "colorErrorBase": "#c54f4e",
  "colorErrorActive": "#982925",
  "colorErrorTextHover": "#77201d",
  "colorErrorText": "#561715",
  "colorErrorTextActive": "#350e0d",
  "colorInfoBg": "#e5f3ff",
  "colorInfoBgHover": "#98c6f2",
  "colorInfoBorder": "#72b0ea",
  "colorInfoBorderHover": "#4c9ae2",
  "colorInfoHover": "#2684da",
  "colorInfoBase": "#006ed2",
  "colorInfoActive": "#005db5",
  "colorInfoTextHover": "#004d96",
  "colorInfoText": "#003d77",
  "colorInfoTextActive": "#001d39",
  "stepsDotSize": 8,
  "stepsCurrentDotSize": 10,
  "rateStarSize": 20,
  "colorBorderBg": "#ffffff",
  "colorLink": "#003d77",
  "colorLinkHover": "#2684da",
  "colorLinkActive": "#005db5",
  "colorHighlight": "#db8d8c",
  "controlOutline": "#efedf7",
  "colorWarningOutline": "#fdf8ee",
  "colorErrorOutline": "#faebeb",
  "fontSizeSM": 12,
  "fontSizeLG": 16,
  "fontSizeXL": 20,
  "fontSizeIcon": 12,
  "fontSizeHeading1": 38,
  "fontSizeHeading2": 30,
  "fontSizeHeading3": 24,
  "fontSizeHeading4": 20,
  "fontSizeHeading5": 16,
  "fontWeightNormal": 400,
  "fontWeightStrong": 700,
  "controlLineWidth": 1,
  "controlOutlineWidth": 2,
  "controlItemBgActive": "#efedf7",
  "controlItemBgActiveHover": "#cfcbe6",
  "controlInteractiveSize": 16,
  "opacityLoading": 0.65,
  "padding": 16,
  "paddingMD": 20,
  "paddingSM": 12,
  "paddingXS": 8,
  "paddingXXS": 4,
  "paddingLG": 24,
  "paddingXL": 32,
  "paddingXXL": 48,
  "margin": 16,
  "marginSM": 12,
  "marginXS": 8,
  "marginXXS": 4,
  "marginLG": 24,
  "marginXL": 48,
  "controlPaddingHorizontal": 12,
  "controlPaddingHorizontalSM": 8,
  "paddingContentHorizontalLG": 24,
  "paddingContentVerticalLG": 16,
  "paddingContentHorizontal": 16,
  "paddingContentVertical": 12,
  "paddingContentHorizontalSM": 16,
  "paddingContentVerticalSM": 8,
  "boxShadow": "0px 1px 2px rgba(0, 0, 0, 0.03), 0px 1px 6px .1px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02)",
  "boxShadowSecondary": "0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px .4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
  "boxShadowCard": "0px 1px 2px .2px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.12), 0px 5px 12px 4px rgba(0, 0, 0, 0.09)",
  "colorItemTextDisabled": "rgba(255, 255, 255, 0.25)",
  "focusPrimary": "0px 0px 0px 2px rgb(0, 0, 0)",
  "focusError": "0px 0px 0px 2px rgb(0, 0, 0)",
  "focusWarning": "0px 0px 0px 2px rgb(0, 0, 0)",
  "segmentedBgColorSelected": "#ffffff",
  "colorMenuDarkBg": "#27214d",
  "colorMenuSubItemBg": "#000c17",
  "paddingTooltipContentVertical": 6
}