import { addons } from '@storybook/manager-api';
import navinaTheme from './NavinaTheme';

addons.setConfig({
  theme: navinaTheme,
  showNav: true,
  showPanel: true,
  panelPosition: 'right',
  selectedPanel: 'controls',
});
