import { create } from '@storybook/theming/create';
import navinaLogo from '../src/assets/images/navina-ds-logo.svg';
import antdNavinaTheme from '../src/theme/antd-tokens/light.json';

export default create({
  base: 'light',
  brandTitle: 'My custom Storybook',
  brandUrl: 'https://example.com',
  brandImage: navinaLogo,
  brandTarget: '_self',
  colorPrimary: antdNavinaTheme.colorPrimary,
  colorSecondary: antdNavinaTheme.colorPrimaryActive,
});
